import {Link} from "react-router-dom";

export default function Home() {

    return <div className="mx-auto max-w-4xl">
        <div className="mt-24 bg-black bg-opacity-80 text-white p-4 border-l-2 border-white">
            <h2 className="text-3xl text-center">
                THIS WEBSITE IS UNDER CONSTRUCTION
                <br/>
                <Link to="/contact" className="underline underline-offset-4">CONTACT</Link>
            </h2>
        </div>
    </div>
}