import React from "react";
import "./App.css";
import {Route, Routes, useNavigate} from "react-router-dom";
import logo from "./jdm.png";
import Home from "./pages/Home";
import Contact from "./pages/Contact";

const NAV_LINKS = [
    ["ABOUT", "/"],
    ["WORK", "/"],
    ["CONTACT", "/contact"]
]

function App() {
    const navigate = useNavigate();

    return (
        <div className="App">
            <nav className="h-36 w-full absolute flex gap-x-1 mt-1 pr-1 sm:m-0 sm:p-0 text-white">
                <div className="bg-black bg-opacity-50 sm:w-32 h-2/3"></div>
                <div className="bg-black w-56 flex-none p-2 flex"
                     onClick={() => {
                         navigate("/")
                     }}>
                    <img src={logo} className="align-middle" alt="JDM Consultants LLC"/>
                </div>
                <div className="bg-transparent h-full sm:h-2/3 w-full">
                    <div className="flex flex-col sm:flex-row gap-1 h-full">
                        {NAV_LINKS.map((pair) => (
                            <div
                                className="hover:cursor-pointer px-4 py-2 bg-black bg-opacity-50 hover:bg-opacity-100 flex grow sm:flex-none"
                                onClick={() => {
                                    navigate(pair[1])
                                }}
                            >
                                <a className="self-center sm:self-end"
                                >{pair[0]}</a>
                            </div>
                        ))}
                        <div className="px-4 py-2 bg-black bg-opacity-50 w-full hidden sm:flex ">
                        </div>
                    </div>
                </div>
            </nav>
            <div className="atlanta-bg pt-40 p-1">
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path="contact" element={<Contact/>} />
                </Routes>
            </div>
        </div>
    );
}

export default App;
