export default function Contact() {

    return <div className="mx-auto max-w-lg">
        <div className="mt-24 bg-black bg-opacity-80 text-white p-4 border-l-2 border-white text-xl text-center">
            <p className="mb-2">
                Darrell Johnson P.E.
            </p>
            <p className="mb-1">
                P.O. BOX 366411<br/>
                ATLANTA, GA 30336
            </p>
            <p className="mb-2">
                <a
                    href="mailto:darrell@jdmconsultantsllc.com"
                    className="underline underline-offset-4"
                >Darrell@jdmconsultantsllc.com</a>
            </p>
            <p className="mb-2">(205) 266-1648</p>
        </div>
    </div>
}